import React, { useEffect, useState } from 'react';
import { parse } from 'querystring';
import { api } from '../helpers/api';
import { RouteConstants } from '../helpers/constants';
import { Modal, Spin } from 'antd';
import Loader from '../components/Loader';
import TicketDetail from '../components/Schedules/TicketDetail';
import { getCookie } from '../helpers/utils';
import { useQuery } from '@tanstack/react-query';
import { fetchTicketManifest } from '../api/fetchTicketManifest';

const TicketReceipt = () => {
  const queryParam = parse(window.location.search.replace('?', ''));
  //const [, setTicketRef] = useState('');
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState({});
  const token = getCookie('oya_token');

  const details = useQuery({
    queryKey: [`manifest-${queryParam?.tn}`],
    queryFn: () =>
      fetchTicketManifest(
        queryParam?.tn && typeof queryParam?.tn !== 'object'
          ? queryParam?.tn
          : ''
      ),
  });

  const getContacts = async () => {
    if (token) {
      setLoading(true);
      try {
        const res = await api.get(RouteConstants.CONTACT_US, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const contactUs = res?.data?.payload?.items.map(
          (contact: any) => contact?.phone
        );
        setTicket((prev) => {
          return { ...prev, contactUs };
        });
      } catch (error: any) {
        // notification.error({
        //   message: error?.response?.data?.message || error?.message,
        // });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getContacts();

    if (details.isSuccess) {
      setTicket(details.data);
    }

    // eslint-disable-next-line
  }, [details.isSuccess]);

  return (
    <div className='relative w-full m-0 flex justify-center items-center  '>
      <Spin
        spinning={details.isLoading || loading}
        indicator={
          <Loader
            bgColor='transparent appear'
            width='w-16'
            height='h-16'
            float
          />
        }
      >
        <Modal
          title=''
          className='my-tickets w-[327px] md:!w-[370px] mt-4 mb-10'
          centered
          mask={false}
          open={true}
          closable={false}
          footer={null}
        >
          <TicketDetail data={ticket} loading={details.isLoading || loading} />
        </Modal>
      </Spin>
    </div>
  );
};

export default TicketReceipt;
