import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CustomButton from '../CustomBtn';

type Props = {
  handleClose: () => void;
};

const Failed = ({ handleClose }: Props) => {
  return (
    <div>
      <div className='w-full flex justify-end items-end'>
        <button
          data-testid='advanceBookingFailed'
          onClick={() => {
            handleClose();
          }}
        >
          <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
        </button>
      </div>

      <div className='flex items-center gap-3'>
        <XMarkIcon className='w-12 h-12 text-red-500' />
        <div className='text-center text-[24px] font-semibold text-red-500 py-1'>
          Payment Failed
        </div>
      </div>
      <div className='flex items-center gap-1'>
        <div className='text-center py-3 text-lg'>
          We could not process your payment. Try again later.
        </div>
      </div>
      <div className='flex items-center justify-center'>
        <CustomButton
          data-testid='closeAdvanceBooking'
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </CustomButton>
      </div>
    </div>
  );
};

export default Failed;
