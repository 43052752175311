import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import loadingImg from '../../assets/img/loading.gif';

type Props = {
  handleClose: () => void;
};

const StillProcessing = ({ handleClose }: Props) => {
  return (
    <div>
      <div className='w-full flex justify-end items-end'>
        <button
          data-testid='processingAdvanceBooking'
          onClick={() => {
            handleClose();
          }}
        >
          <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
        </button>
      </div>
      <div className='flex justify-center mt-4 items-center gap-3'>
        <img src={loadingImg} alt='processing' />
      </div>

      <div className='flex justify-between items-center'>
        <div className='text-center py-3 text-base'>
          This is taking longer than expected. This could be as a result of the
          payment provider's prompt failing.
        </div>
      </div>

      <button
        data-testid='dismissAdvanceBooking'
        className='text-oya-red-50 font-semibold hover:text-red-400'
        onClick={() => {
          handleClose();
        }}
      >
        Dismiss
      </button>
    </div>
  );
};

export default StillProcessing;
