import { useState, useEffect } from 'react';
import { Row, Col, Skeleton, Pagination } from 'antd';
import CustomButton from '../CustomBtn';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import notFound from '../../assets/img/not_found.svg';
import LoadingPointScheduleCard from '../Schedules/LoadingPointScheduleCard';
import Search from './Search';
import { useDebounce } from '@uidotdev/usehooks';
import HshsMobileHome from '../../mobile/hshs/HshsMobileHome';
import BranchesFilter from './BranchesFilter';
import PickupFilter from './PickupFilter';
import { fetchSchedules } from '../../api/hshs/fetchSchedules';

function HshsSchedules() {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string | null>('');
  const debounceSearchName = useDebounce(searchValue, 1200);
  const [isMobile, setIsMobile] = useState(true);
  const [pickup, setPickup] = useState<number | null>(null);
  const [school, setSchool] = useState<number | null>(null);

  const [pagination, setPagination] = useState({
    pageSize: 50,
    pageIndex: 0,
  });

  const handlePagination = (page: number) => {
    setPagination({
      ...pagination,
      pageIndex: page - 1,
    });
  };

  const { isLoading, data, isSuccess } = useQuery({
    queryKey: [
      'search-loading-point-schedules',
      debounceSearchName,
      pickup,
      school,
    ],
    queryFn: () => {
      return fetchSchedules(
        pagination.pageIndex,
        pagination.pageSize,
        searchValue,
        pickup,
        school
      );
    },
  });

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (isMobile) {
    return <HshsMobileHome />;
  }

  return (
    <div className='flex gap-3 xl:mr-[8rem] lg:mr-[6rem]'>
      <div className='w-full h-screen'>
        <div className='my-5 w-full flex justify-center items-center'>
          <Search
            handleChange={(value) => {
              setSearchValue(value);
            }}
          />
        </div>
        <div className='flex items-center w-full justify-between lg:justify-start pb-5'>
          <div className='font-semibold m-3 sm:text-[20px] text-lg text-oya-gray lg:flex-[0.3]'>
            Available Trips
          </div>
          <div className='flex gap-4  items-center flex-[0.65]'>
            <BranchesFilter
              handleChange={(value) => {
                setSchool(value);
              }}
            />
            <PickupFilter
              handleChange={(value) => {
                setPickup(value);
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <Row className='mx-3 gap-3'>
            <Col xs={24} md={12} lg={7}>
              <div className='p-2 border shadow rounded-md'>
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className='p-2 border shadow rounded-md'>
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className='p-2 border shadow rounded-md'>
                <Skeleton paragraph active />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {isSuccess ? (
              <>
                {data?.items?.length < 1 ? (
                  <div className=' py-3 '>
                    <div className='flex flex-col justify-center items-center h-full'>
                      <img
                        className='w-[50%] sm:w-[40%] h-auto mt-8'
                        src={notFound}
                        alt='404'
                      />
                      <div className='font-bold sm:text-xl text-center mt-8 text-gray-500'>
                        No schedules available
                      </div>
                      <div className='mt-2'>
                        <CustomButton
                          onClick={() => {
                            navigate('/book-advance');
                          }}
                        >
                          Book for advance ticket
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                      {data?.items?.map((item: any, index: number) => (
                        <div className='w-full min-w-[220px]' key={index}>
                          <LoadingPointScheduleCard item={item} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className='w-full flex justify-center items-center'>
                <div className='mt-[10%] text-center'>
                  An unexpected error occured
                </div>
              </div>
            )}
          </>
        )}

        {isSuccess && data && (
          <div className='pt-10 w-full flex justify-end items-end'>
            {data.total > pagination.pageSize && (
              <div className='pagination w-full justify-end items-end'>
                <Pagination
                  pageSize={pagination.pageSize}
                  current={pagination.pageIndex + 1}
                  total={data.total}
                  onChange={(page) => {
                    handlePagination(page);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default HshsSchedules;
