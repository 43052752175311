import React from 'react';
import { Divider } from 'antd';

type Props = {
  countdown: number;
};

const Processing = ({ countdown }: Props) => {
  return (
    <div>
      <div className='flex justify-center mt-4'>
        <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
          Processing payment
        </div>
      </div>
      <div className='text-center py-3'>
        You will receive a prompt to enter your Pin to complete this
        transaction.
      </div>
      <div className='text-center text-[20px] py-4'>{countdown}</div>
      <Divider className='my-4' />
      <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
        Processing...
      </div>
    </div>
  );
};

export default Processing;
