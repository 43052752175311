import { RouteConstants } from '../../helpers/constants';
import { api } from '../../helpers/api';

type ReturnType = {
  total: number;
  items: Array<any>;
  offset: number;
};

export const fetchSchedules = async (
  page: number,
  pageSize: number,
  filters?: any,
  pickup?: number | null,
  school?: number | null
): Promise<ReturnType> => {
  try {
    const offset = page * pageSize;
    const filterArray: Array<any> = [];

    if (filters && filters.trim().length > 0) {
      filterArray.push(
        {
          f: 'loading_point.source.name',
          o: 'contains',
          p: [`${filters}`],
          c: 'OR',
        },
        {
          f: 'loading_point.destination.name',
          o: 'contains',
          p: [`${filters}`],
          c: 'OR',
        },
        {
          f: 'loading_point.label',
          o: 'contains',
          p: [`${filters}`],
        }
      );
    }

    if (school) {
      filterArray.push({
        f: 'branch.id',
        o: '=',
        p: [school],
      });
    }

    if (pickup) {
      filterArray.push({
        f: 'loading_point_id',
        o: '=',
        p: [pickup],
      });
    }

    const { data } = await api.get(
      `${
        RouteConstants.SEARCH_LOADING_POINT_SCHEDULES
      }?filters=${JSON.stringify(
        filterArray
      )}&sorting=created_at:desc&limit=${pageSize}&offset=${offset}`
    );

    return data?.payload;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};
