import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DropdownIndicator from '../ui/DropdownIndicator';
import { useQuery } from '@tanstack/react-query';
import { fetchBranches } from '../../api/hshs/fetchBranches';

type Props = {
  handleChange: (value: number | null) => void;
};

const BranchesFilter = ({ handleChange }: Props) => {
  const [branches, setBranches] = useState<Array<any>>([]);

  const { isLoading, data, isSuccess } = useQuery({
    queryKey: ['hshs-branches-list'],
    queryFn: fetchBranches,
  });

  useEffect(() => {
    if (isSuccess) {
      const res = data?.items?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      setBranches(res);
    }

    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <Select
      options={branches}
      onChange={(value: any) => {
        handleChange(value?.value);
      }}
      isLoading={isLoading}
      placeholder='School'
      components={{ DropdownIndicator }}
      isClearable
      isSearchable
      styles={{
        container(base, props) {
          return {
            ...base,
            height: '40px',
            borderWidth: '1px',
            borderRadius: '12px',
            borderColor: '#939291',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#fff',
          };
        },
        control(base, props) {
          return {
            ':active': {
              borderColor: '#ccc',
            },
            width: '100%',
            display: 'flex',
          };
        },
      }}
    />
  );
};

export default BranchesFilter;
