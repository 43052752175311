import { api } from '../../helpers/api';
import { RouteConstants } from '../../helpers/constants';

type ReturnType = {
  total: number;
  items: Array<any>;
  offset: number;
};
export const fetchBranches = async (): Promise<ReturnType> => {
  try {
    const { data } = await api.get(`${RouteConstants.HSHS_BRANCHES}`);

    return data?.payload;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};
