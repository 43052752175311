import AdSlider from '../AdsSlider';
import moment from 'moment';

import OyaLogo from '../../assets/img/logo.svg';
import GPRTULogo from '../../assets/img/gprtu.svg';
import OyaTicketBack from '../../assets/img/oya-tic-bg.png';
import OyaTicketFooter from '../../assets/img/ticket-footer.svg';
import WhiteOyaLogo from '../../assets/img/oya-white.svg';
import AppStoreDownload from '../../assets/img/appstore-download.svg';
import GooglePlayDownload from '../../assets/img/googleplay-download.svg';
import PageNotFound from '../../pages/PageNotFound';
import { Skeleton } from 'antd';

const TicketDetail = ({ data, loading }: { data: any; loading: boolean }) => {
  const contactUs = data?.contactUs || [];

  const conductor = data?.conductor;
  return (
    <div className='w-full m-0 flex justify-center items-center overflow-hidden'>
      {loading ? (
        <Skeleton paragraph />
      ) : (
        <>
          {data?.ticket_no ? (
            <div
              className='relative w-full flex flex-col !bg-center !bg-cover !bg-no-repeat'
              style={{
                background: `url(${OyaTicketBack})`,
              }}
            >
              {/* {showclose && (
                <AiOutlineClose
                  className="cursor-pointer absolute right-0 mt-5 mr-5 text-lg sm:text-xl"
                  onClick={onclose}
                />
              )} */}
              <div>
                <AdSlider />
              </div>

              <div className='flex flex-col items-center p-2 m-2 md:p-4 md:mx-5 md:mb-2 md:mt-5 bg-white rounded-lg'>
                <div className='text-center text-oya-gray mb-3 '>
                  <div>TICKET</div>
                  <div className='font-semibold'>{data?.station?.name}</div>
                  <div className='font-semibold'>
                    {data?.from?.name} - {data?.to?.name}
                  </div>
                </div>
                <div className='grid grid-cols-2 gap-x-3 gap-y-2 m-3 md:mx-5 w-full'>
                  <div className='text-left md:m-0'>
                    <h1 className=' font-bold text-oya-gray-200'>FARE</h1>
                    {data?.schedule?.prepaid === true ? (
                      <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal'>
                        PREPAID
                      </h3>
                    ) : (
                      <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal'>
                        {data?.amount_payable?.currency}{' '}
                        {data?.amount_payable?.amount || '-'}
                      </h3>
                    )}
                  </div>
                  <div className='text-left md:m-0 '>
                    <h1 className=' font-bold text-oya-gray-200'>
                      SERIAL NUMBER
                    </h1>
                    <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal'>
                      {data?.ticket_no || '-'}
                    </h3>
                  </div>
                  <div className='text-left md:m-0 '>
                    <h1 className='text-oya-gray-200 font-bold'>CONDUCTOR</h1>

                    <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal capitalize'>
                      {conductor?.name || '-'}
                    </h3>
                  </div>
                  <div className='text-left md:m-0 '>
                    <h1 className='text-oya-gray-200 font-bold'>DRIVER </h1>

                    <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal'>
                      {data?.driver?.name || '-'}
                    </h3>
                  </div>
                  <div className='text-left md:m-0'>
                    <h1 className='text-oya-gray-200 font-bold'>PASSENGER</h1>
                    <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal'>
                      {data?.owner?.name}
                    </h3>
                  </div>
                  <div className='text-left md:m-0'>
                    <h1 className='text-oya-gray-200 font-bold'>TRIP DATE</h1>
                    <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal'>
                      {moment(data?.schedule?.departures_at).format(
                        'MMM DD, YYYY'
                      )}
                      {/* {bus?.departure_time} */}
                    </h3>
                  </div>
                  <div className='text-left  md:m-0'>
                    <h1 className=' font-bold'>VEHICLE NUMBER</h1>
                    <h3 className='text-oya-gray font-bold text-oya-ghana-body-normal'>
                      {data?.bus?.reg_number || '-'}
                    </h3>
                  </div>
                </div>

                <div className='w-full border-t border-border-color md:mt-4 md:pt-3'>
                  <div className='flex flex-col items-center justify-center px-2 md:px-4'>
                    <h2 className='text-bgCol text-base font-medium text-center tracking-tighter'>
                      FOR ENQUIRIES CONTACT
                    </h2>
                    <div className='w-full flex justify-center items-center flex-wrap'>
                      {contactUs?.map((el: any, index: number) => (
                        <a
                          href={`tel:${el}`}
                          className='text-oya-gray font-bold text-oya-ghana-body-sb hover:underline px-2 sm:pr-1 sm:pl-0 text-oya-ghana-header-h7 hover:!text-oya-ghana-red'
                          key={index}
                        >
                          {el}
                        </a>
                      ))}
                    </div>
                    <div className='pt-1'>
                      <a
                        className='hover:underline text-oya-red-100 font-bold text-oya-ghana-body-normal-large'
                        href={'https://oyaghana.com'}
                        target='_blank'
                        rel='noreferrer'
                      >
                        https://oyaghana.com
                      </a>
                    </div>
                    <div className='flex items-center justify-center gap-x-2 pt-1'>
                      <a
                        href='https://gprtu.oyaghana.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={GPRTULogo}
                          alt='gprtu logo'
                          width={35}
                          height={35}
                        />
                      </a>
                      <div className='text-oya-ghana-header-h6 font-bold'>
                        powered by
                      </div>
                      <a
                        href={
                          window?.location?.hostname.includes('com')
                            ? 'https://oyaghana.com'
                            : 'https://oyaghana.dev'
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={OyaLogo} alt='logo' width={35} height={35} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Third section begins */}
              {/* <div className="rounded-lg m-2 md:mx-5 md:my-3 flex justify-center">
                <Button
                  type="primary"
                  size="middle"
                  className="rounded-lg bg-oya-ghana-red text-white text-oya-ghana-body-normal-large px-8"
                  href={`trips/${parsedQueryString?.tn}?q=rate`}
                  target="_blank"
                >
                  Rate Driver
                </Button>
              </div> */}

              <div className='bg-oya-ghana-red rounded-lg p-2 m-2 md:py-5 md:px-3 md:mx-5 md:mb-5 md:mt-3'>
                <div
                  className='!bg-bottom !bg-contain !bg-no-repeat pt-3 pb-2'
                  style={{ background: `url(${OyaTicketFooter})` }}
                >
                  <div className='flex items-center justify-center gap-x-1 pb-8'>
                    <a
                      href={
                        window?.location?.hostname.includes('com')
                          ? 'https://oyaghana.com'
                          : 'https://oyaghana.dev'
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={WhiteOyaLogo}
                        alt='oya'
                        width={35}
                        height={35}
                      />
                    </a>
                    <a
                      href='https://apps.apple.com/gh/app/oya/id1623866371'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={AppStoreDownload}
                        alt='playstore'
                        width={90}
                        height={35}
                      />
                    </a>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.oyaghana.oyaapp_porter&hl=en&gl=US'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={GooglePlayDownload}
                        alt='playstore'
                        width={90}
                        height={35}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PageNotFound />
          )}
        </>
      )}
    </div>
  );
};

export default TicketDetail;
