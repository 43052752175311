import CustomButton from '../CustomBtn';
import { getCookie } from '../../helpers/utils';
import { Divider } from 'antd';
import { XMarkIcon } from '@heroicons/react/24/outline';
import checkMarkImg from '../../assets/img/check_mark.svg';

type Props = {
  handleClose: () => void;
  handleViewReceipt: () => void;
  handleOpenRegister: () => void;
  handleOpenSignin: () => void;
};

const Success = ({
  handleClose,
  handleViewReceipt,
  handleOpenRegister,
  handleOpenSignin,
}: Props) => {
  const token = getCookie('oya_token');

  return (
    <>
      {token ? (
        <div>
          <div className='w-full flex justify-end items-end'>
            <button
              data-testid='advanceBookingSuccess'
              onClick={() => {
                handleClose();
              }}
            >
              <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
            </button>
          </div>
          <div className='flex flex-col justify-center mt-4 items-center gap-3'>
            {/* <img src={successImg} alt='processing' /> */}
            <div className='border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='18'
                viewBox='0 0 25 18'
                fill='none'
              >
                <path
                  d='M1.5 9.29247L8.79247 16.5849L23.34 2'
                  stroke='#2ABB7F'
                  stroke-width='2.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
            <div className='text-center text-[24px] font-semibold text-seat-green py-2'>
              Booking successful!
            </div>
            <div className='text-[16px] text-center'>
              Your trip has been booked successfully.
            </div>

            <button
              data-testid='viewAdvanceBookingReceipt'
              className=' text-oya-red-50 text-center'
              onClick={() => {
                handleViewReceipt();
              }}
            >
              View receipt
            </button>
          </div>
        </div>
      ) : (
        <div className='success-popup'>
          <div className='w-full flex justify-end items-end'>
            <button
              data-testid='advanceTicketSuccess'
              onClick={() => {
                handleClose();
              }}
            >
              <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
            </button>
          </div>
          <div className='flex flex-col justify-center mt-4 items-center gap-3'>
            {/* <img src={successImg} alt='processing' /> */}
            <div className='border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center'>
              <img src={checkMarkImg} alt='check mark' />
            </div>
            <div className='text-center text-[24px] font-semibold text-seat-green py-2'>
              Booking successful!
            </div>
          </div>
          <div className='text-[16px] text-center'>
            Your trip has been booked successfully.
          </div>
          <div className='text-oya-red-50 text-center text-[16px]'>
            <button
              data-testid='viewAdvanceTicketReceipt'
              onClick={() => {
                handleViewReceipt();
              }}
            >
              View receipt
            </button>
          </div>
          {!token && (
            <>
              <Divider className='my-4' />
              <div className='text-center text-[16px]'>
                Sign up with Oya to{' '}
                <span className='text-oya-red-50'>see your trip history</span>,{' '}
                <span className='text-oya-red-50'>rate your trip</span>,
                <span className='text-oya-red-50'>report incidents</span> and{' '}
                <span className='text-oya-red-50'>share your trip</span>
              </div>

              <div className='flex justify-center items-center gap-4 mt-4'>
                <CustomButton
                  data-testid='createAccount'
                  onClick={() => {
                    handleOpenRegister();
                  }}
                >
                  Create account
                </CustomButton>
                <CustomButton
                  data-testid='login'
                  onClick={() => {
                    handleOpenSignin();
                  }}
                  variant='secondary'
                >
                  Login
                </CustomButton>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Success;
