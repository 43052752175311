import { Input } from 'antd';
import React from 'react';
import locationImg from '../../assets/img/destination_location.svg';

type Props = {
  handleChange: (value: string) => void;
};

const Search = ({ handleChange }: Props) => {
  return (
    <div className='lg:w-[70%] w-full '>
      <Input
        className='h-[50px] rounded-[12px] sm:shadow-lg focus:outline-none active:outline-none'
        placeholder='Search for a trip'
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        prefix={<img src={locationImg} alt='location' />}
      />
    </div>
  );
};

export default Search;
