export const NODE_ENV = process.env.NODE_ENV;
export const RIDE_MS_URL = process.env.REACT_APP_RIDE_URL;
export const AUTH_MS_URL = process.env.REACT_APP_AUTH_MS_URL;
export const ACCOUNTS_MS_URL = process.env.REACT_APP_ACCOUNTS_MS_URL;

export const COOKIES_TOKEN_NAME = 'oya_token';
export const COOKIES_USER_NAME = 'oya_user';
export const COOKIES_USR = 'oya_usr';
export const COOKIES_ROLE = 'oya_selected_user_role';
export const COOKIES_SERVICE = 'oya_selected_service';
export const DB_PREF = 'oya_';

export const COOKIES_SECURED = process.env.REACT_APP_COOKIES_SECURED;
export const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;

export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

const walletID = window.localStorage.getItem('walletID');

export const RouteConstants = {
  VERIFY_OTP: '/v2/auth/verify',
  SEND_OTP: '/v2/auth/send-verification',
  REQUEST_RESET_PIN_OTP: '/v2/auth/pin/request-reset-code',
  RESET_PIN: '/v2/auth/pin/reset',
  DELETE_USER: '/v1/profile/trash',
  UPLOAD_FOTO: '/v1/profile/image/presigned-upload',
  DELETE_FOTO: '/v1/remove-profile-picture',

  ROUTES: '/v2/routes',
  SCHEDULES: '/v2/schedules',
  HSHS_BRANCHES: '/v2/hshs-branches',
  HSHS_LOADING_POINT: '/v2/hshs-loading-points',
  SCHEDULES_SEARCH: '/v2/search-schedules',
  SEARCH_LOADING_POINT_SCHEDULES: '/v2/search-loading-point-schedules',
  LOADING_POINT_SCHEDULES: '/v2/loading-point-schedules',
  ADVANCE_BOOKING: '/v2/advanced-bookings',
  ACCOUNTS: '/v2/accounts',
  LOGIN: '/v2/auth/phone-login',
  BUS_TYPE: '/v2/bus-types',
  REGISTER: '/v2/auth/register',
  TICKETS: '/v2/tickets',
  MIDROUTES: '/v2/midroutes',
  TRAVEL_TIME: '/v2/loading-point-travel-times',
  LOADING_POINT: '/v2/loading-points',
  LOADING_POINT_BOOKING: '/v2/loading-point-bookings',
  LUGGAGE_SIZE: '/v2/luggage-sizes',
  PAYMENTS: '/v2/payments',
  PROFILE: '/v1/profile',
  TRANSACTIONS: '/v2/transactions',
  SINGLE_GUEST_SCHEDULE: '/v2/guests/schedules',
  BULK_GUEST_SCHEDULE: '/v2/guests/schedules',
  GUEST_TICKET: '/v2/guests/tickets',
  PREPAID_SCHEDULE: '/v2/prepaid-schedules',
  NETWORK_PROVIDER: '/v2/providers',
  USER_WALLET: '/v2/me/wallets',
  USER_WALLET_HISTORY: `/v2/me/wallets/${walletID}/transactions`,
  FUND_WALLET: `/v2/me/wallets/${walletID}/fund`,
  WITHDRAW_WALLET: `/v2/me/wallets/${walletID}/transfer`,
  GUEST_ADVANCE_TICKET: '/v2/guest-loading-point-bookings',
  ADVANCE_BOOKING_GUEST: '/v2/guest-advanced-bookings',
  BUS_FEATURES: `/v2/buses`,
  GET_ADVANCE_TICKET_PRICE: `/v2/get-advanced-ticket-price`,
  GET_TICKET_PRICE: `/v2/get-ticket-price`,
  POPULAR_ROUTES: `/v2/popular-routes`,
  TICKET_MANIFEST: 'v2/guests/tickets/find-by-manifest-code',
  CONTACT_US: 'v2/contacts?types=TICKET_CONTACT&kinds=PHONE',
};

export const data = [
  {
    id: 1,
    image:
      'https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954967/beehive_oya/h8dyqno1oazldczq3mhe.png',
    title: 'Book Your Ticket in Advance',
    description:
      'Secure your seat and beat the rush! Book your ticket in advance for a hassle-free journey.',
  },
  {
    id: 2,
    image:
      'https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954957/beehive_oya/w0kvevvtejbszopjvxtm.png',
    title: 'Incident Report',
    description:
      'Your safety matters! Report any incidents during your ride easily with our quick and responsive incident reporting feature.',
  },
  {
    id: 3,
    image:
      'https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954969/beehive_oya/dnsjfjqtkuubyp7nswph.png',
    title: 'Speed Report',
    description:
      'Travel at your comfort zone! Monitor the bus speed in real-time and report any concerns to ensure a smooth and comfortable ride at your preferred pace.',
  },
  {
    id: 4,
    image:
      'https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954952/beehive_oya/fmc6qezzzujo4nrz1suu.png',
    title: 'Driver and Station Rating',
    description: `Give credit where it's due! Rate your driver and station experience to let us know how we're doing. Your feedback drives our excellence!`,
  },
];

export const buses = [
  {
    id: 1,
    branch: {
      backdrop_image_url: null,
    },
    route: {
      id: 1,
      from: {
        name: 'Aburi',
      },
      to: {
        name: 'Madina',
      },
    },
    code: '142412',
    bus: {
      passenger_capacity: 12,
    },
    onboard_passengers: 4,
  },
];

export const fares = [
  {
    id: 1,
    price: 70.0,
    bus_type: 'Coach',
    premium: false,
    seats: 25,
  },
  {
    id: 2,
    price: 120.0,
    bus_type: 'Premium mini bus',
    premium: true,
    seats: 15,
  },
  {
    id: 3,
    price: 90.0,
    bus_type: 'Sprinter',
    premium: false,
    seats: 15,
  },
];

export const ADS = [
  {
    id: 1,

    image: '/images/advance_ticket.png',
  },
  {
    id: 2,

    image: '/images/join_midroute.png',
  },
  {
    id: 3,
    image: '/images/rate_trip.png',
  },
  {
    id: 4,
    image: '/images/speed_checker.png',
  },
  {
    id: 5,
    image: '/images/share_trip.png',
  },
  {
    id: 6,
    image: '/images/report_accident.png',
  },
];
